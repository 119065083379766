/* styles.css */
.clients-section {
    padding: 4rem 0;
    background-color: #f9f9f9;
    text-align: center;
}

.clients-section .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.clients-section h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: orange; /* Theme color */
}

.certifications {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.certification-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 200px;
}

.certification-card img {
    max-width: 100%;
    height: auto;
    margin-bottom: 0.5rem;
}

.certification-card p {
    font-size: 1rem;
    color: #000; /* Text color */
}
