/* styles.css */
.footer {
    background-color: #333;
    color: #fff;
    padding: 2rem 0;
}

.footer .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.footer-section {
    flex: 1;
    min-width: 200px;
}

.footer-section h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: orange; /* Theme color */
}

.footer-section p,
.footer-section ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-section ul li {
    margin-bottom: 0.5rem;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: orange; /* Theme color */
}

.footer-section .social-icons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.footer-section .social-icons a {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.footer-section .social-icons a:hover {
    color: orange; /* Theme color */
}

.footer-bottom {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.footer-bottom p {
    margin: 0;
}
