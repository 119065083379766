/* styles.css */
.pricing-plan-section {
    padding: 4rem 0;
    background-color: #fff;
    text-align: center;
}

.pricing-plan-section .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.pricing-plan-section h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: orange; /* Theme color */
}

.pricing-plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.pricing-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 300px;
}

.pricing-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #000; /* Text color */
}

.pricing-card .price {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: orange; /* Theme color */
}

.pricing-card ul {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem 0;
    text-align: left;
}

.pricing-card ul li {
    margin-bottom: 0.5rem;
    color: #000; /* Text color */
}

.pricing-card button {
    background-color: orange; /* Theme color */
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pricing-card button:hover {
    background-color: #ff9900; /* Darker shade of the theme color */
}
