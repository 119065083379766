/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-logo a {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px;
  transition: all 0.3s ease;
}

.navbar-menu li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  transition: color 0.3s ease;
}

.navbar-menu li a:hover {
  color: #007BFF;
}

.navbar-button .btn {
  background-color: #ff9900;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navbar-button .btn:hover {
  background-color: #0056b3;
}

.navbar-toggler {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-left: 20px;
  outline: none;
}

.navbar-toggler-icon {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .navbar-menu {
      display: none;
      flex-direction: column;
      /* gap: 10px; */
      position: absolute;
      top: 70px;
      right: -12px;
      background-color: white;
      padding: 20px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 12000;
      width: 100%;
      /* border: 1px red solid; */
  }

  .navbar-menu.open {
      display: flex;
  }

  .navbar-toggler {
      display: flex;
  }

  .navbar-logo a{
    font-size: 13px;
  }
}
