/* OurServices.css */
.our-services {
    padding: 50px 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .our-services h2 {
    font-size: 36px;
    color: #000;
    margin-bottom: 40px;
  }
  
  .services-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .service-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .service-card:hover {
    transform: scale(1.05);
  }
  
  .service-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  
  .service-card h3 {
    font-size: 24px;
    color: #000;
    margin-bottom: 10px;
  }
  
  .service-card p {
    font-size: 16px;
    color: #555;
  }
  