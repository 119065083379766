/* HeroSection.css */
.hero-section {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 100px 20px;
  background-color: #f9f9f9;
}

.hero-content {
  max-width: 600px;
  text-align: center;
  margin-top: 40px;
}

.hero-content h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 18px;
  color: #666;
  margin-bottom: 40px;
}

.hero-content .hero-btn {
  background-color: #ff9900;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.hero-content .hero-btn:hover {
  background-color: #0056b3;
}

.hero-image-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image-wrapper {
  width: 80%;
  /* padding-top: 80%; */
  border-radius: 50%;
  background-color: orange;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image {
  width: 100%;
  height: auto;
}

.hero-text {
  position: absolute;
  top: -20px;
  right: -20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hero-text span {
  font-size: 18px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .hero-section {
      flex-direction: row;
  }

  .hero-content {
      text-align: left;
      margin-top: 0;
  }

  .hero-content h1 {
      font-size: 48px;
  }
}
