/* Newsletter.css */
.newsletter-section {
    padding: 4rem 1rem;
    background-color: #f9f9f9;
    text-align: center;
}

.newsletter-section .container {
    max-width: 600px;
    margin: 0 auto;
}

.newsletter-section h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: orange; /* Theme color */
}

.newsletter-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.newsletter-form input {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 1rem;
    color: #000; /* Text color */
    background-color: #fff;
    width: 100%;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.newsletter-form button {
    background-color: orange; /* Theme color */
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.newsletter-form button:hover {
    background-color: #ff9900; /* Darker shade of the theme color */
}

@media (min-width: 768px) {
    .newsletter-form {
        flex-direction: row;
        align-items: center;
    }

    .newsletter-form input {
        flex: 1;
        width: auto;
    }

    .newsletter-form button {
        width: auto;
    }
}
