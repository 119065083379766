/* Faq.css */
.case-study-section {
    padding: 4rem 1rem;
    background-color: #f9f9f9;
    text-align: center;
}

.case-study-section .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.case-study-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: orange; /* Theme color */
}

.case-studies {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.case-study-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 100%;
    max-width: 600px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.case-study-card:hover {
    background-color: #f1f1f1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.case-study-card.expanded {
    background-color: #f1f1f1; /* Slightly different color when expanded */
}

.case-study-summary {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.case-study-summary h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #000; /* Text color */
}

.case-study-summary p {
    font-size: 1rem;
    color: #000; /* Text color */
}

.toggle-arrow {
    font-size: 1.2rem;
    font-weight: bold;
    transition: transform 0.3s ease;
}

.case-study-card.expanded .toggle-arrow {
    transform: rotate(45deg); /* Rotate the arrow when expanded */
}

.case-study-details {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid #ddd;
    font-size: 1rem;
    color: #000; /* Text color */
    overflow: hidden;
    transition: height 0.3s ease, opacity 0.3s ease;
}

.case-study-details p {
    margin: 0;
}

@media (min-width: 768px) {
    .case-study-section h2 {
        font-size: 2.5rem;
    }

    .case-study-summary h3 {
        font-size: 1.75rem;
    }

    .case-study-summary p {
        font-size: 1.125rem;
    }

    .toggle-arrow {
        font-size: 1.4rem;
    }

    .case-study-details {
        font-size: 1.125rem;
    }
}

@media (min-width: 1200px) {
    .case-study-section h2 {
        font-size: 3rem;
    }

    .case-study-summary h3 {
        font-size: 2rem;
    }

    .case-study-summary p {
        font-size: 1.25rem;
    }

    .toggle-arrow {
        font-size: 1.6rem;
    }

    .case-study-details {
        font-size: 1.25rem;
    }
}
