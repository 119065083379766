/* Contact.css */
.contact-form-section {
    padding: 4rem 1rem;
    background-color: #fff;
    text-align: center;
}

.contact-form-section .container {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 2rem;
}

.contact-form-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: orange; /* Theme color */
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-group label {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #000; /* Text color */
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 1rem;
    color: #000; /* Text color */
    background-color: #f9f9f9;
}

.form-group textarea {
    resize: vertical;
    height: 150px;
}

button {
    background-color: orange; /* Theme color */
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
}

button:hover {
    background-color: #ff9900; /* Darker shade of the theme color */
}

@media (min-width: 768px) {
    .contact-form-section h2 {
        font-size: 2.5rem;
    }

    .form-group label {
        font-size: 1.125rem;
    }

    .form-group input,
    .form-group textarea {
        font-size: 1.125rem;
    }

    button {
        font-size: 1.125rem;
    }
}

@media (min-width: 1200px) {
    .contact-form-section h2 {
        font-size: 3rem;
    }

    .form-group label {
        font-size: 1.25rem;
    }

    .form-group input,
    .form-group textarea {
        font-size: 1.25rem;
    }

    button {
        font-size: 1.25rem;
    }
}
