/* About.css */
.about-us-section {
    padding: 4rem 1rem;
    background-color: #fff;
    text-align: center;
}

.about-us-section .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.about-us-content {
    padding-bottom: 2rem;
    text-align: left;
}

.about-us-image {
    width: 100%;
}

.about-us-section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: orange; /* Theme color */
}

.about-us-section p {
    font-size: 1rem;
    line-height: 1.5;
    color: #000; /* Text color */
}

.about-us-image img {
    max-width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
    .about-us-section .container {
        flex-direction: row;
        justify-content: space-between;
    }

    .about-us-content {
        flex: 1;
        padding-right: 2rem;
        padding-bottom: 0;
        text-align: left;
    }

    .about-us-image {
        flex: 1;
        width: auto;
    }
}

@media (min-width: 1200px) {
    .about-us-section h2 {
        font-size: 2.5rem;
    }

    .about-us-section p {
        font-size: 1.125rem;
        line-height: 1.75;
    }
}
